import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const displayDateFormat = "YYYY/MM/DD";
export const searchDateFormat = "YYYY-MM-DD";

export const string2Date = (dateString: string | undefined) => {
  if (!dateString) return "N/A";
  return dayjs(dateString).format(displayDateFormat);
};

export const string2FullDate = (
  dateString: string | undefined,
  separator: string = "-",
) => {
  const dateWithoutOffset = dateString?.slice(0, 19);
  if (!dateWithoutOffset) return "N/A";

  return dayjs
    .utc(dateWithoutOffset)
    .format(`YYYY${separator}MM${separator}DD HH:mm`);
};
